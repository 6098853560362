<template>
  <div class="footer">
    <div class="content" style="display: flex;align-items: center;justify-content: space-between;">
      <!-- <div class="box">
        <div class="about" v-for="(v, i) in floor_navs._sub" :key="i">
          <div class="title">{{ v.title }}</div>
          <div class="item" v-for="(j, k) in v._sub" :key="k" @click="goSub(j)">{{ j.title }}</div>
        </div>
      </div> -->
      <div class="company title">{{company_name}}</div>
      <div class="put">
        <div :class="v.value?'linkBox':'noLink' " v-for="(v, i) in links._sub" :key="i" @click="goSub(v)" v-if="v.title.trim().length > 0">{{ v.title }}</div>
      </div>
      <!-- <div>中国互联网和不良信息举报中心</div> -->
    </div>
  </div>
</template>

<script>
import { floor } from "@/api/common.js"
export default {
  data () {
    return {
      company_name: '',
      links: {
        _sub: []
      },
      floor_navs: {
        _sub: []
      }
    }
  },
  created () {
    let footerData = this.$store.state.footerData
    if (Object.keys(footerData).length) {
      this.company_name = floor.company_name
      this.links = floor.links
      this.floor_navs = floor.floor_navs
      return
    }
    floor({}).then(({ data }) => {
      footerData = data
      this.company_name = data.company_name
      // console.log(data,"taaaaaaaaa");
      this.links = data.links
      this.floor_navs = data.floor_navs
    })
  },
  methods: {
    goSub (v) {
      if(v.value)window.open(v.value, '_blank')
    }
  },
};
</script>

<style lang="less" scoped>
.footer {
  // position: fixed;
  // bottom: 0;
  // margin-top: 11rem;
  background: #272c32;
  width: 100%;
  // height: 22.94rem;
  .title {
    font-size: 1.13rem;
    font-weight: 600;
    color: #ffffff;
    line-height: 2.5rem;
  }
  .content {
    // padding: 3rem 0;
    margin: 0 auto;
    font-weight: 600;
    color: #bcbcbc;
    font-size: 0.88rem;
    line-height: 2.2rem;
  }
  .company {
    //margin-top: 1.94rem;
  }
  .box {
    display: flex;
    flex-wrap: wrap;
    .about {
      width: 12rem;
      .title {
        font-size: 1.13rem;
        font-weight: 600;
        color: #ffffff;
        line-height: 2.5rem;
      }
      .item {
        font-size: 0.88rem;
        font-weight: 600;
        line-height: 2rem;
        cursor: pointer;
      }
    }
  }
  .put {
    display: flex;
    flex-wrap: wrap;
    .linkBox {
      margin-right: 10px;
      cursor: pointer;
    }
    .noLink{
      margin-right: 10px;

      // margin-right: 5rem !important;
    }
  }
}
// footer {
//   box-sizing: border-box;
//   width: 100%;
//   height: 367px;
//   background: #272c32;
//   padding-top: 70px;
//   padding-bottom: 100px;
//   color: #bcbcbc;
//   .hz {
//     font-size: 18px;
//     font-family: PingFangSC-Semibold, PingFang SC;
//     font-weight: 600;
//     color: #fff;
//   }
//   .company {
//     margin-top: 30px;
//     color: #bcbcbc;
//   }
//   .jurisdiction {
//     margin-top: 10px;
//     margin-bottom: 10px;
//     display: flex;
//     li {
//       margin-right: 80px;
//     }
//   }
//   li {
//     margin-top: 10px;
//     list-style-type: none;
//     font-size: 14px;
//     font-family: PingFangSC-Semibold, PingFang SC;
//     font-weight: 600;
//     color: #bcbcbc;
//   }
// }
</style>
