import request from "@/Utils/api";

// 注册
export function register(data) {
    return request({
      url: "api/v1_0_0.login/phone_reg",
      method: "post",
      data,
      notNeed:false
    });
  }
//   发送验证码
  export function getCode(data) {
    return request({
      url: "api/common.sendmsg/do_sendmsg",
      method: "post",
      data,
      notNeed:false
    });
  }
//   手机号登录
  export function phoneLogin(data) {
    return request({
      url: "api/v1_0_0.login/phone",
      method: "post",
      data,
      notNeed:false
    });
  }
  //   ID登录
  export function login(data) {
    return request({
      url: "api/v1_0_0.login",
      method: "post",
      data,
      notNeed:false
    });
  }
  // 找回密码
  export function editPassword(data) {
    return request({
      url: "api/v1_0_0.login/reset_pwd",
      method: "post",
      data,
      notNeed:false
    });
  }
    // 游客登录
    export function tourist(data) {
      return request({
        url: "api/v1_0_0.login/tourist",
        method: "post",
        data,
        notNeed:false
      });
    }
        // 服务协议
        export function service_agreement(data) {
          return request({
            url: "api/v1_0_0.index/service_agreement",
            method: "post",
            data,
            notNeed:false
          });
        }