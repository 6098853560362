<template>
  <el-container class="pc-layout" style="height: 100vh">
    <el-header
      style="
        height: 4rem;
        padding: 0;
        background: #ffffff;
      "
    >
      <Header></Header>
    </el-header>
    <el-container :style="elStyle">
      <el-main class="layout-main" style="padding: 0">
        <exception
          :class="'view-top' + (show_Footer ? ' view-top-footer' : '')"
        >
          <router-view
            :class="
              'view-top content' + (show_Footer ? ' view-top-footer' : '')
            "
            style="padding-bottom: 20px"
          />
        </exception>
        <Footer v-if="show_Footer"></Footer>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
// import { userInfo2 } from "@/api/me.js";

import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  components: {
    Footer,
    Header,
  },
  name: "pcLayout",
  data() {
    return {
      show_Footer: true,
      elStyle: {
        overflow: "auto",
      },
      infoData: {},
    };
  },
  watch: {
    "$store.state.user_token"(v, o) {
      if (v) {
        this.getUserInfo();
      }
    },
  },
  created() {
    // this.getInfo()
    let user_token = localStorage.getItem("user_token");
    let user_id = localStorage.getItem("user_id");
    let token = localStorage.getItem("auth_token");
    if (token || (user_token && user_id)) {
      this.checkLogin(user_id);
      this.getUserInfo();
    }
  },
  mounted() {
    this.getSyetemConfig();
    this.initRoute(this.$route);
  },
  methods: {
    routeListener(a, b, c) {
      this.initRoute(a);
    },
    // getInfo(){
    //   userInfo2().then(res=>{
    //     console.log(res,"asdasdasdasdasdasdasd");
    //     this.infoData=res.data
    //   })
    // },
    userInfoListener({ id, refresh }, old, is_change) {
      if (refresh && refresh !== old.refresh) {
        this.getUserInfo();
      }
      if (is_change) {
        this.$store.dispatch(location.$module + "/getMenuList").then((data) => {
          localStorage.setItem(
            "menuList",
            this.$tool.encrypt(JSON.stringify(data), "menuList")
          );
          this.$store.commit("SET", { key: "menuList", value: [...data] });
        });
      }
      if (id + "" !== old.id + "") {
        // if(old.id) this.$socket.delListen('/api/v1_0_0.user/is_login/user_id/'+old.id);
        this.checkLogin(id);
      }
    },
    checkLogin(user_id) {
      if (user_id) {
        let user_token = localStorage.getItem("user_token");
        let token = localStorage.getItem("auth_token");
        // this.$socket.listen('/api/v1_0_0.user/is_login/user_id/'+user_id , token ? {auth_token:token} :{user_id,user_token} , ({data})=>{
        //   if((data || {}).code === 201){
        //     try{ this.$store.state.loginOutCallback && this.$store.state.loginOutCallback();  }catch (e) {}
        //     this.$nextTick(()=>{
        //       this.$store.dispatch('clearLogin');
        //       this.$store.commit('SET',{key:'lodinShow',value:true})
        //     })
        //   }
        // });
      }
    },
    initRoute(a) {
      this.show_Footer =
        [
          "/problemList/questAnswer",
          "/problemList/student/practiceExam",
          "/problemList/tiDanRecord",
        ].indexOf(a.path) === -1;
      if (["/forum/forumSee"].indexOf(a.path) > -1) {
        this.elStyle.backgroundColor = "#ffffff";
      } else {
        this.elStyle.backgroundColor = "#f5f5f5";
      }
      this.$jquery(this.$el)
        .find(".layout-main")
        .eq(0)
        .css("background-color", this.elStyle.backgroundColor);
    },
    getSyetemConfig() {
      this.$http
        .post("/api/v1_0_0.index/system", {}, { cache: 60 })
        .then((res) => {
          this.$store.commit("SET", {
            key: "systemCofing",
            value: res.data || [],
          });
        });
    },
    getUserInfo() {
      this.$http
        .post("/xapi/user/getInfo")
        .then(({ data }) => {
          data.type = parseInt(data.type);
          localStorage.setItem(
            "userInfo",
            this.$tool.encrypt(JSON.stringify(data), "userInfo")
          );
          this.$store.commit("SET", {
            key: "userInfo",
            value: { ...data, refresh: false },
          });
          this.$store.commit("SET", { key: "userType", value: data.type });
          this.$store.commit("SET", { key: "user_id", value: data.id });
          this.$store.commit("SET", { key: "lodinShow", value: false });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="less" >
.feedback-box {
  min-width: 350px;
}
.el-button-group .el-button--text:not(:last-child) {
  margin-right: 5px !important;
}
.el-table td.el-table__cell .cell,
.el-input__icon {
  line-height: 30px;
}
.el-input,
.el-form-item__content {
  line-height: 28px;
}
.el-form-item__label {
  height: 30px;
  line-height: 30px;
}
.el-input__inner {
  height: 30px;
  padding: 0 10px;
  line-height: 24px;
  * {
    line-height: inherit !important;
  }
}
.feedback-box {
  width: 38vw;
  border: none;
  .el-message-box__header {
    background: #00957e;
    .el-message-box__title {
      color: #fff;
    }
    .el-icon-close {
      font-size: 20px;
      cursor: pointer;
      color: #fff;
    }
    .el-icon-close:before {
      content: "\e78d";
    }
    .el-message-box__headerbtn .el-message-box__close:hover {
      color: #fff !important;
    }
  }
  .el-message-box__content {
    padding: 0 3rem 0 3rem;
    .el-message-box__input {
      padding-top: 5px;
      .el-textarea__inner {
        min-height: 96px !important;
      }
    }
  }
  .el-message-box__btns {
    padding: 0 3rem 2rem 3rem;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.el-image-viewer__canvas img {
  max-width: 90% !important;
  max-height: 90% !important;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.nothing {
  text-align: center;
  line-height: 1.5;
  color: #909399;
}
.my_answer {
  display: flex;
  align-items: center;
}
.check_record {
  font-size: 28px;
  color: #00957e;
  margin-left: 1rem;
}
.close_record {
  font-size: 28px;
  color: rgb(255, 59, 59);
  margin-left: 1rem;
}
.el-loading-parent--relative {
  z-index: 998;
}
.el-message-box__headerbtn .el-message-box__close:hover {
  color: #00957e !important;
}
.confirm-green .el-message-box__headerbtn .el-message-box__close:hover {
  color: #fff !important;
}
.confirm-green {
  .el-message-box__header {
    background: #00957e;
    .el-message-box__title {
      color: #fff;
      padding: 0 1rem;
    }
    .el-message-box__headerbtn {
      top: 14px;
      .el-message-box__close {
        color: #fff;
        border-radius: 50%;
        border: 1px solid;
        padding: 1px;
      }
    }
  }
  .el-message-box__content {
    padding: 1rem 15px;
  }
}
.qzhh {
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}
.qzhh * {
  max-width: 100%;
}
p {
  img {
    max-width: 100% !important;
    height: auto !important;
  }
}
//富文本按钮对齐
.ql-toolbar.ql-snow .ql-formats {
  line-height: 20px;
}
// 消息提示框样式
.el-message {
  min-width: 300px !important;
}
//修改滚动条样式
.el-table__body-wrapper::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}
:focus {
  outline: 0;
}
.el-dropdown-menu {
  border: 0 !important;
}
.el-popper[x-placement^="bottom"] .popper__arrow {
  border-bottom-color: #fff !important;
}
.el-dropdown-menu__item {
  text-align: center;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgb(128, 128, 128);
  --webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgb(63, 63, 63);
}

.bdshare-slide-style-r0 .bdshare-slide-button {
  display: none !important;
}
.view-top {
  //margin-top: 6.25rem;
  //padding-bottom: 8rem;
  background-color: inherit;
  min-height: calc(100vh - 6.25rem);
  // min-height: calc(100vh - 4rem);
}
.view-top-footer {
  // min-height: calc(100vh - 21.25rem - 20px);
  min-height: calc(100vh - 7.5rem);
}
@media all and (min-width: 1280px) {
  .content {
    width: 98%;
    // max-width: 1280px;
    // min-width: 1280px;
    margin: 0 auto;
  }
  .hidden-cont {
    display: none !important;
  }
}
@media all and (max-width: 1280px) {
  .content {
    max-width: 100%;
  }
  .visible-cont {
    display: none !important;
  }
}
.el-table::before {
  height: 0;
}
.el-radio {
  white-space: normal !important;
}
.el-radio__label {
  line-height: 1.4;
}
.shenluehao,
[class^="shenluehao-"] {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.shenluehao-2 {
  -webkit-line-clamp: 2;
}
.shenluehao-3 {
  -webkit-line-clamp: 3;
}
.shenluehao-4 {
  -webkit-line-clamp: 4;
}
.shenluehao-5 {
  -webkit-line-clamp: 5;
}
</style>