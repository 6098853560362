<template>
  <div class="header">
    <div class="content">
      <div class="logo" v-href="'/'" @click="$handleRoute('/')">
        <img src="../assets/index/logo.png" alt />
      </div>
      <div class="nav" style="display: flex;line-height: 45px;">
        <!-- <template v-for="(v, i) in headerMenuList.filter(v=>v.hidden !== true)">
          <el-dropdown placement="bottom" :key="i" @command="handleCommand"> -->
        <template >
          <el-dropdown placement="bottom" v-for="(v, i) in headerMenuList.filter(v=>v.hidden !== true)" :key="i" @command="handleCommand">
            <span v-href="v.path"
                  :class="['el-dropdown-link', v.path && currentPath === v.path ? 'dropdown-link' : '' ]"
                  @click="navHandle(v)"
                  :title="v.name"
                  style="overflow: hidden;"
            >
              {{ v.name }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <template v-for="(j, k) in v.children">
                <el-dropdown-item :key="k" v-if="j.hidden !== true">
                  <span style="display: inline-block; width: 100%; overflow: hidden;height: 100%;"
                        :class="[j.path && $route.fullPath === j.path ? 'header-dropdown-link' : '' ]"
                        @click="navHandle({...j,disabled:j.disabled || v.disabled})"
                        v-href="j.path"
                        :title="j.name"
                  >{{ j.name }}</span>
                </el-dropdown-item>
              </template>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </div>

      <div class="nav-right" >
        <!-- <div class="search" v-if="false">
          <el-input placeholder="搜索 课程/题单" v-model="search"></el-input>
          <el-button icon="el-icon-search"></el-button>
        </div> -->
        <div class="login">
          <el-dropdown @command="handleCommand" placement="bottom" >
            <div style="display: flex;align-items: center;">
               <el-badge :value="infoData.msg_notread" class="item" :hidden="!infoData.msg_notread">
              <span class="el-dropdown-link">
                <el-avatar style="width: 40px; height: 40px" :src="userInfo.headimg"></el-avatar>
              </span>
            </el-badge>
          
            </div>
           
            <!-- <el-badge :value="msgNotread" class="item" :hidden="!infoData.msg_notread">
              <span class="el-dropdown-link">
                <el-avatar style="width: 40px; height: 40px" :src="userInfo.headimg"></el-avatar>
              </span>
              </el-badge> -->
            <el-dropdown-menu slot="dropdown">
              <template  v-if="!userInfo.id">
                <el-dropdown-item command="登录/注册">登录/注册</el-dropdown-item>
              </template>
              <template v-else>
                <el-dropdown-item command="个人中心"><span v-href="'/me'">个人中心</span></el-dropdown-item>
                <el-dropdown-item command="我的反馈"><span v-href="'/me/meFeedback'">我的反馈</span></el-dropdown-item>
                <el-dropdown-item command="退出登录">退出登录</el-dropdown-item>
                <el-dropdown-item command="申请成为老师" v-if="userType === 1" v-href="'applyTeach'">申请成为老师</el-dropdown-item>
              </template>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div style="margin-left: 10px">
          {{ userInfo.nickname }}
        </div>
      </div>
    </div>
    <SignRegister ref="SignRegister"></SignRegister>
    <Binding ref="Binding"></Binding>
  </div>
</template>

<script>
// import { userInfo2} from "@/api/me.js"

import SignRegister from "./SignRegister/SignRegister.vue"
import { mapState } from "vuex"
import Binding from "@/components/Binding/Binding.vue"
export default {
  components: { SignRegister , Binding },
  data () {
    return {
      clickPath: null,
      paramsList: [],
      index: 0,
      search: "",
      headerMenuList:[],
      isLoginIndex:0,
      infoData: {},
      msgNotread:0
    }
  },
  watch: {
    menuList:{
      immediate:true,
      deep:true,
      handler(v){
        this.headerMenuList = [...v];
        this.initMenuLoad(v);
      }
    },
    '$store.state.msg_notread':{
      immediate:true,
      deep:true,
      handler(v){
        // console.log(v,"vvvvvvvv");
        this.infoData.msg_notread = v
      }
    }
  },
  created () {
    this.path = this.$route.path
    // this.getInfo()
  },
  computed: {
    ...mapState(['user_token' , 'menuList','currentCrumbList']),
    currentPath(){
      return this.clickPath || (this.currentCrumbList.length > 1 ? (this.currentCrumbList[1] || {}).path : null) || this.$route.path;
    }
  },
  methods: {
    userInfoListener(a,b){
      if(b.id && !a.id){
        this.$store.state.loginOutCallback && this.$store.state.loginOutCallback();
      }
    },
    // getInfo(){
    
    // },
    routeUpdate(){
      this.headerMenuList = [...this.menuList];
      this.clickPath = null;
      this.initMenuLoad(this.menuList);  
      if(localStorage.getItem('auth_token')){
      this.$http.get("/api/v1_0_0.user/msg_not_read").then((res)=>{
              this.infoData=res.data
                })
      }
      // this.$http.get("/api/v1_0_0.user/msg_not_read").then((res)=>{
      //   this.infoData=res.data
      //     })

      // userInfo2().then(res=>{
      //   // console.log(res,"ressss");
      //   this.infoData=res.data
      // })
    },
    initMenuLoad(menuList){
      let exception = { status:0, path:null, goPath:null  };
      for (let i = 0; i < menuList.length; i++) {
        if(menuList[i].path && this.currentPath === menuList[i].path){
          if(menuList[i].disabled){
            exception = { status:403, path:this.currentPath,};
          }
          break;
        }
      }
      this.$store.commit("SET" , {key:"exception" , value:exception})
    },
    navHandle (to) {
      console.log(to,"访问路由");
      if(this.userInfo.id > 0 && to.disabled){
        return this.$alert("您没有权限访问【"+(to.name)+"】页面");
      }else{
        //  let cid = to.path.match(/=(\S*)/)[1];
        // console.log(to.path.indexOf('=')+1,"to.path.indexOf('=')+1")
         let typeId = to.path.substring(to.path.indexOf('=')+1);
        this.clickPath = to.fullPath;
        // 点击子项，传递路由后面的参数，cid为课程
        
        // 跳转课程 
        if(to.path.indexOf('cid')>0){
          console.log(typeId,"cidddddd")
          this.$handleRoute({cid:typeId},to.path)

        // 跳转比赛考试作业 
        }else if(to.path.indexOf('type')>0){
         console.log(typeId,"typettypeIdtypeIdype")
          this.$handleRoute({type:typeId},to.path)

        }
      }
    },
    handleCommand (e) {
      if (e === "登录/注册") {
        if (this.userInfo.id > 0) {
          return this.$message.info("已登录")
        }
        this.$store.commit("SET" , {key:"lodinShow" , value:true})
      }
      if (e === "退出登录") {
        try{
          this.$store.state.loginOutCallback && this.$store.state.loginOutCallback();
        }catch (e) { console.log("退出登录");}
        this.$store.dispatch('clearLogin');
        this.$message.success({duration: 1000,message: '退出登录成功'})
        this.$store.commit("SET" , {key:"msg_notread" , value:0})

        this.$handleRoute("/")
      }
      if (e === "我的反馈") {
        this.$handleRoute("/me/meFeedback")
      }
      if (e === "个人中心") {
        this.$handleRoute("/me")
      }
      if(e === '申请成为老师'){
        this.$handleRoute("applyTeach")
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-header-submenu,.el-header-menu{
  background-color: var(--color-white);
}
.el-header-menu .el-menu-item.is-active,.el-header-submenu .el-menu-item.is-active{
  background-color: #ffff !important;
}
.el-header-submenu{
  background-color: var(--color-white);
}
.el-header-menu .active,.el-header-submenu .active{
  color: var(--color-primary);
  font-weight: 600;
}
.header {
  width: 100%;
  //height: 6.25rem;
  height: 4rem;
  background: var(--color-white);
  //position: fixed;

  top: 0;
  z-index: 999;
  .content {
    ::v-deep {
      .el-dropdown {
        // font-size: 1.13rem;
        font-size: 1rem;
        color: var(--color-black);

        cursor: pointer;
        font-weight: 500;
      }

      .el-dropdown-menu__item {
        text-align: center;
      }
      .el-menu-item,
      .el-submenu__title {
        font-size: 1.13rem;
        color: var(--color-black);

      }

      .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
        display: none;
      }
      .el-menu.el-menu--horizontal {
        border: 0;
      }

      .el-submenu__title {
        padding: 0 1rem;
      }
    }
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav {
      // width: 60%;
      width: 76%;
      margin: 0 0.8rem;
      justify-content: space-between;
      .el-dropdown{
        max-height: 60px;
        overflow: hidden;
      }
      .el-dropdown-link {
        padding: 0 0.5rem;
        margin: 0 0.5rem;
        line-height: 30px;
        min-width: 30px;
        text-align: center;
        display: inherit;
      }
      .dropdown-link {
        color: var(--color-primary);
        // color: #008992;

        font-weight: 600;
      }
    }
    .nav-right {
      display: flex;
      align-items: center;
    }
    .logo {
      cursor: pointer;
      // margin-right: 65px;
      img {
        // width: 10.38rem;
        // height: 3.81rem;
        width: 8.38rem;
        height: 2.81rem;
      }
    }
  }
  ::v-deep {
    .el-avatar > img {
      width: 100%;
    }
  }
  /deep/ .search {
    // width: 19.38rem;
    // margin-left: 75px;
    margin-right: 21px;
    display: flex;
    .el-input__inner {
      height: 32px;
      line-height: 32px;
      border-radius: 40px 0 0 40px;
      border: 1px solid var(--color-primary);
      border-right: none;
    }
    .el-button {
      height: 32px !important;
      width: 53px;
      padding: 0;
      text-align: center;
      box-sizing: border-box;
      border-radius: 0 40px 40px 0;
      border: 1px solid var(--color-primary);
      border-left: none;
      background: var(--color-primary);
      .el-icon-search {
        color: var(--color-white);
      }
    }

  }
  .login {
    width: 40px;
    height: 40px;
  }
}
.header-dropdown-link{
  color: var(--color-primary);
}
.el-dropdown-menu/deep/ .el-dropdown-menu__item{
  line-height: 36px;
  height: 36px;
}
</style>
<style lang="less">
</style>