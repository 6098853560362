<template>
  <div class="sing">
    <el-dialog ref="dialog" :visible.sync="valisle"
               :width="loginType === 'teachRegister'? '1100px' :'400px'"
               :before-close="handleClose"
               :center="true"
               @closed="closed"
               @open="open"
               :modal-append-to-body="false"
               :custom-class="loginType === 'teachRegister'? 'teach-dialog' :'sign-dialog'"
               :close-on-click-modal="false"
               destroy-on-close
               :z-index="5999"
    >
      <template slot="title">
        <span>{{loginTypeList[loginType]}}</span>
      </template>
      <template slot="footer">
        <p class="agreement">
          注册/登录即代表你已阅读并同意
          <span @click="agreement('user_agreement')">《用户协议》、</span>
          <span @click="agreement('privacy_policy','隐私政策')">《隐私政策》</span>
          <!-- <span @click="agreement('s_privacy_policy')">《儿童隐私保护政策》</span> -->
        </p>
        <el-divider @click="loginType = 'register'" v-if="loginType === 'signIn'||loginType === 'verification'||loginType === 'message'||loginType === 'tourist'">立即注册</el-divider>
        <el-divider @click="loginType = 'signIn'" v-else>立即登录</el-divider>
      </template>
      <!-- 找回密码 -->
      <div v-if="loginType === 'retrieve'">
        <div class="ipts">
          <el-input v-model="signData.phone" placeholder="手机号"></el-input>
          <div class="ipt">
            <el-input v-model="signData.code" placeholder="短信验证码"></el-input><span class="code" @click="clickGetCode">{{
              show ? "获取验证码" : count + "s"
            }}</span>
          </div>
          <el-input v-model="signData.password" placeholder="请输入新密码" type="password" maxlength="20"></el-input>
          <el-input v-model="signData.password2" placeholder="请输入确认密码" maxlength="20" type="password"></el-input>
        </div>
        <div class="dialog-footer">
            <el-button type="primary" @click="handler(editPassword)">确 定</el-button>
          </div>
      </div>
      <div v-if="loginType === 'teachRegister'">
        <teach-register v-model="signData" style="padding: 20px 20px 0" ref="teachRegister">
         <template slot="after">&nbsp;</template>
        </teach-register>
        <div style="padding: 0 20px">
          <div class="dialog-footer"  slot="after">
            <el-button type="primary" @click="handler(teachRegister)">确 &nbsp; &nbsp; 定 &nbsp; &nbsp; 注 &nbsp; &nbsp; 册</el-button>
        </div>
        </div>
      </div>
      <!-- 注册 -->
      <div v-if="loginType === 'register'">
        <el-row :gutter="20" style="margin-bottom: 15px;">
          <el-col :span="8" :offset="16" style="text-align: right;">
            <el-button type="primary" size="mini" @click="loginType = 'teachRegister'">注册教师</el-button>
          </el-col>
        </el-row>
        <div class="ipts">
          <el-input v-model="signData.realname" placeholder="姓名"></el-input>
          <el-input v-model="signData.phone" placeholder="手机号"></el-input>
          <el-input v-model="signData.password" placeholder="请输入8~20位含字母和数字" maxlength="20" type="password"></el-input>
          <div class="ipt">
            <el-input v-model="signData.code" placeholder="短信验证码"></el-input><span class="code" @click="clickGetCode">{{
              show ? "获取验证码" : count + "s"
            }}</span>
          </div>
        </div>
        <div class="dialog-footer">
            <el-button type="primary" @click="handler(clickRegister)">确 定</el-button>
          </div>
        <div class="btns">
          <!-- <el-button type="text" @click="forgetPassword">忘记密码?</el-button> -->
          <div class="btns_right">
            <!-- <el-button type="text" @click="loginType = 'message'">短信验证码登录</el-button> -->
            <!-- <el-button type="text">文字按钮</el-button> -->
          </div>
        </div>
      </div>
      <!-- 登录 -->
      <div v-if="loginType === 'signIn'">
        <div class="ipts">

          <el-input v-model="signData.phone" placeholder="ID/手机号" maxlength="15"></el-input>
          <el-input v-model="signData.password" placeholder="密码" type="password" maxlength="20"></el-input>
        </div>
        <div class="dialog-footer">
            <el-button type="primary" @click="handler(clickLogin)">确 定</el-button>
          </div>
        <div class="btns">
          <el-button type="text" @click="forgetPassword">忘记密码?</el-button>
          <div class="btns_right">
            <el-button type="text" @click="loginType = 'message'">短信验证码登录</el-button>
            <!-- <el-button type="text" @click="loginType = 'tourist'">游客登录</el-button> -->
          </div>
        </div>
      </div>
      <!-- 验证手机号 -->
      <div v-if="loginType === 'verification'">
        <div class="ipts">
          <el-input v-model="signData.phone" placeholder="手机号"></el-input>
          <div class="ipt">
            <el-input v-model="signData.code" placeholder="短信验证码"></el-input><span class="code" @click="clickGetCode">{{
              show ? "获取验证码" : count + "s"
            }}</span>
          </div>
        </div>
        <div class="dialog-footer">
            <el-button type="primary" @click="verificationShow = false">确 定</el-button>
          </div>
        <div class="btns">
          <el-button type="text" @click="forgetPassword">忘记密码?</el-button>
          <div class="btns_right">
            <el-button type="text" @click="loginType = 'signIn'">密码登录</el-button>
            <!-- <el-button type="text">文字按钮</el-button> -->
          </div>
        </div>
      </div>
      <!-- 短信验证登录 -->
      <div v-if="loginType === 'message'">
        <div class="ipts">
          <el-input v-model="signData.phone" placeholder="手机号"></el-input>
          <div class="ipt">
            <el-input v-model="signData.code" placeholder="短信验证码"></el-input><span class="code" @click="clickGetCode">{{
              show ? "获取验证码" : count + "s"
            }}</span>
          </div>
        </div>
        <div class="dialog-footer">
            <el-button type="primary" @click="handler(clickPhoneLogin)">确 定</el-button>
          </div>
        <div class="btns">
          <el-button type="text" @click="forgetPassword">忘记密码?</el-button>
          <div class="btns_right">
            <el-button type="text" @click="loginType = 'signIn'">密码登录</el-button>
          </div>
        </div>
      </div>
      <!-- 游客登录 -->
      <div v-if="loginType === 'tourist'">
        <div class="ipts">

          <el-input v-model="signData.realname" placeholder="姓名"></el-input>
          <el-input v-model="signData.phone" placeholder="手机号"></el-input>
          <div class="ipt">
            <el-input v-model="signData.code" placeholder="短信验证码"></el-input><span class="code" @click="clickGetCode">{{
              show ? "获取验证码" : count + "s"
            }}</span>
          </div>
        </div>
        <div class="dialog-footer">
            <el-button type="primary" @click="handler(clickPhoneLogin)">确 定</el-button>
        </div>
        <div class="btns">
          <el-button type="text" @click="forgetPassword">忘记密码?</el-button>
          <div class="btns_right">
            <el-button type="text" @click="loginType = 'signIn'">密码登录</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  register,
  getCode,
  phoneLogin,
  login,
  editPassword,
  tourist
} from "@/api/signRegister.js"
import { userInfo2} from "@/api/me.js"

import md5 from "js-md5"
import { mapState } from "vuex"
import router from "@/router";
import TeachRegister from "@/components/SignRegister/teachRegister";
import store from "../../store";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {TeachRegister},
  data () {
    //这里存放数据
    return {
      realname:'',
      valisle: false,
      show: true,
      count: "",
      loginType:'signIn',
      loginTypeList: {
        signIn : "KenCoding登录",
        register : "KenCoding注册",
        retrieve : "找回密码",
        verification : "验证手机号",
        message : "短信验证登录",
        tourist : "游客登录",
        teachRegister : "注册教师",
      },
      signData:{
        phone: "",
        code: "",
        password: "",
        password2: "",
      },
      input: 1,
      serviceData: {},
      timer: null,
      infoData:{}
    }
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState(["lodinShow",'LoginRequests']),
  },
  watch:{
    "$store.state.lodinShow":{
      immediate:true,
      handler(v , o){
        if(v !== o) {
          if (v) {
            this.loginType = 'signIn'
          }
        }
        this.valisle = v === true;
      }
    },
    loginType(){
      this.signData = {phone:this.signData.phone};
    },
    valisle(v){
      this.$store.commit("SET" , {key:'lodinShow',value:v})
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.loginType = 'signIn'
  },
  //方法集合
  methods: {
    handler (fn) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      console.log("param --- handler" , fn);
      this.timer = setTimeout(() => {
        fn()
      }, 300)
    },
    agreement (e,t) {
      this.$handleRoute({type: e,title:t ||'用户协议'} , 'agreement' , '_blank')
    },
    open () {

    },
    keyDown (e) {
      if (e.keyCode == 13 && this.lodinShow) {
        if (['signIn'].indexOf(this.loginType) > -1) {
          this.handler(this.clickLogin)
        }
        if (['tourist' , 'message'].indexOf(this.loginType) > -1) {
          this.handler(this.clickPhoneLogin)
        }
        if (['register'].indexOf(this.loginType) > -1) {
          this.handler(this.clickRegister)
        }
        if (['retrieve'].indexOf(this.loginType) > -1) {
          this.handler(this.editPassword)
        }
        if (['teachRegister'].indexOf(this.loginType) > -1) {
          this.handler(this.teachRegister)
        }
      }
     
    },

    closed () {
      this.signData = {};
    },
    teachRegister(){
      this.$refs.teachRegister.validate((form , valid , errors)=>{
        if(valid){
          console.log(form,"form")
          console.log(this.signData,"this.signData")
          // return
          // this.$http.post('/xapi/login.login/teachRegister' , {...form}).then(({data})=>{
          this.$http.post('/xapi/login.login/teachRegister' , {...this.signData}).then(({data})=>{
            login({ u_code: form.phone, password: form.password })
                .then(({data}) => {
                  this.loginSuccess(data);
                  this.$handleMessage("注册并登录成功！" ,"success")
                })
          }).catch(({code , msg})=>{
            if(code === 210){
              this.$handleMessage(msg ,"success");
              this.loginType = 'signIn';
            }
          });
        }else if(errors.length > 0){
          this.$handleMessage(errors[0].message , 'error');
        }
      } , true);
    },
    handleValid(validateFields){
      validateFields = validateFields || Object.keys(this.signData);
      var data = {};
      let $d = {phone:{name:"手机号",regex:/^1[3456789]\d{9}$/},code:{name:"验证码"},realname:{name:"姓名"},password:{name:"密码"},password2:{name:"密码"}};
      for (const vfk in validateFields) {
        let filed = isNaN(vfk) ? vfk : validateFields[vfk]
        let filedObj = {...$d[filed] || {}};
        if(validateFields[vfk] instanceof Object){
          filedObj = Object.assign(filedObj , validateFields[vfk]);
        }else if(isNaN(vfk) && validateFields[vfk] && validateFields[vfk] instanceof String){
          filedObj.asField = validateFields[vfk];
        }
        let isNotEmpty = this.signData[filed] || this.signData[filed] === "0"
        if(!isNotEmpty && !(filedObj.required === false)){
          this.$message({message: (filedObj.name || filed)+"不能为空",type: "error",duration: 1800,})
          data = false;
          break;
        }
        if(isNotEmpty && filedObj.regex && !filedObj.regex.test(this.signData[filed])) {
          this.$message({message: (filedObj.name || filed)+"不正确",type: "error",duration: 1800,})
          data = false;
          break;
        }
        if(filedObj.contField && this.signData[filed] !== this.signData[filedObj.contField]){
          this.$message({message: "两次"+(filedObj.name || filed)+"不一致",type: "error",duration: 1800,})
          data = false;
          break;
        }
        if(filedObj.asField){
          data[filedObj.asField] = this.signData[filed];
        }else{
          data[filed] = this.signData[filed];
        }
      }
      return data;
    },
    // 点击手机验证码登录
    clickPhoneLogin () {
      var param = this.handleValid(this.touristShow ? ['phone' , 'code','realname'] : ['phone' , 'code'])
          , api = this.touristShow ? tourist :phoneLogin;
      console.log("param --- clickPhoneLogin" , param);
      if(!param){
        return ;
      }
      api(param)
        .then((res) => {
          this.loginSuccess(res.data);
          this.$message({
            message: "登录成功",
            type: "success",
            duration: 1000,
          })
        }).catch()
    },
    // 点击ID登录
    clickLogin () {
      var param = this.handleValid({phone:{asField:'u_code',name:"ID/手机号"},password:""});
      if(!param){
        return ;
      }

      login(param).then((res) => {
          this.loginSuccess(res.data);
          this.$message({
            message: '登录成功',
            type: "success",
            duration: 1000,
          })
          this.$http.get("/api/v1_0_0.user/msg_not_read").then((res)=>{
              // this.infoData=res.data
            this.$store.commit("SET" , {key:"msg_notread" , value:res.data.msg_notread})
          })

      //     userInfo2().then(res=>{
      //       console.log(res,"ressss");
      //       this.$store.commit("SET" , {key:"msg_notread" , value:res.data.msg_notread})
      // })
        }).catch()
    },
    loginSuccess:function(data){
      this.signData = {};
      this.valisle = false;
      localStorage.setItem("user_id", data.user_id)
      localStorage.setItem("user_token", data.user_token)
      localStorage.setItem("auth_token", data.authToken || '')
      this.$store.commit('SET' , {key:'user_token' ,value: data.user_token})
      this.$store.commit('SET' , {key:'user_id' ,value: data.user_id})
      this.$store.dispatch('web/getMenuList')
      if(this.LoginRequests.length > 0){
        this.$store.commit("SET" , {key:"LoginRequests" , value:[]})
        // this.$nextTick(()=>{
        //   location.reload();
        // })
      }else{
        this.$store.commit('SET' , {key:'userInfo' ,value: {... this.userInfo,refresh:true}})
      }
    },
    // 点击注册并登录
    clickRegister () {
      var param = this.handleValid({phone:"",password:"",code:"",realname:{required:false}});
      if(!param){
        return ;
      }
      register({...param})
        .then((res) => {
          phoneLogin({ phone: param.phone, code: param.code })
            .then((res) => {
              this.loginSuccess(res.data);
              this.$message({
                message: "注册成功，并登录",
                type: "success",
                duration: 1000,
              })
            }).catch()
        }).catch()
      // this.registerShow=false
    },
    // 点击获取验证码
    clickGetCode () {
      if (!this.show) {
        return
      }
      var param = this.handleValid({phone:""});
      if(!param){
        return ;
      }
      let time_stamp = new Date().getTime()
      let sign = md5(md5(param.phone + "bibao" + time_stamp))
      getCode({ phone: param.phone, time_stamp: time_stamp, sign: sign })
        .then((res) => {
          this.$message.success("验证码发送成功")
          this.getCode()
        })
       
    },
    // 60S验证码
    getCode () {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    // 点击找回密码
    editPassword () {
      var param = this.handleValid({phone:"",code:"",password:"",password2: {contField:"password",asField:"confirm_password"}});
      if(!param){
        return ;
      }
      editPassword({...param})
        .then((res) => {
          if (localStorage.getItem("user_token")) {
            this.$message({
              message: "密码修改成功请退出重新登录",
              type: "success",
            })
          } else {
            this.$message({
              message: "密码修改成功",
              type: "success",
            })
          }
          this.signData = {};
          this.valisle = false;
        }).catch()
    },
    // 找回密码
    handleClose () {
      this.valisle = false;
      if((this.$route.path + '/').indexOf('/questAnswer/') > -1){
        router.push('/');
      }
    },
    // 点击忘记密码
    forgetPassword () {
      this.signData = {};
      this.loginType = 'retrieve'
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    // setTimeout(() => {
    //   this.valisle = this.lodinShow === true;
    // }, 300)
    window.addEventListener("keydown", this.keyDown)

  },
};
</script>
<style lang='less' scoped>
::v-deep{
  .el-divider__text{
    color: #00957e;
  }
  .ipts{
    .el-input + .el-input{
      margin-top: 10px;
    }
    .el-input__inner {
      height: 40px;
      padding: 0 15px;
      line-height: 40px;
      *{
        line-height: inherit !important;
      }
    }
  }
  .ipts + .dialog-footer{
    margin-top: 20px;
  }
  .el-dialog__footer,.el-dialog__footer .el-divider{
    margin-top: 30px;
  }
}
.agreement {
  span {
    
    cursor: pointer;
  }
}
.sing {
  .ipt {
    display: flex;
    span {
      height: 40px;
      line-height: 40px;

      display: inline-block;
      white-space: nowrap;
      // padding: 0 5%;
      text-align: center;
      width: 45%;
      letter-spacing: 1px;
      background-color: #00957e;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
  ::v-deep {
    p span{
      color: #8d8d8d !important;
    }
    .el-dialog:not(.teach-dialog) {
      top: 50%;
      transform: translateY(-75%);
    }
    .el-dialog__header {
      padding: 20px 35px;
      border-bottom: 1px solid #d7d7d7;
      display: flex;
      justify-content: center;
    }
    .el-dialog__body {
      padding: 20px 20px 0 20px;
    }
    .el-dialog--center .el-dialog__footer{
      padding:0 0 1px;
      .agreement{
        padding:0 20px;
      }
    }
    .teach-dialog{
      .el-dialog__body {
        padding: 0;
      }
    }
    .sign-dialog .el-input {
      margin-bottom: 15px;
      .el-input__inner {
        border: 1px solid #d7d7d7;
        border-radius: 2px;
      }
    }
    //.el-divider--horizontal {
      //margin: 28px 0 10px;
    //}
    .el-divider__text {
      cursor: pointer;
    }
  }
  .dialog-footer {
    .el-button {
      margin-top: 10px;

      width: 100%;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      background: #00957e;
      font-weight: 600;
      color: #ffffff;
      border-color: #00957e;
    }
  }
  p {
    margin: 15px 0;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8d8d8d;
    span {
      color: #00957e;
    }
  }
  .code {
    cursor: pointer;
  }
  .btns {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .el-button {
      // padding: 3%;
      padding-bottom: 0;
      margin: 0;
      color: #b8b8b8;
    }
    .btns_right {
      display: flex;
      flex-direction: column;
      .el-button {
        text-align: right;
      }
    }
  }
}
</style>

