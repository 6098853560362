<template>
  <div class="Binding">
    <!-- 绑定 -->
    <el-dialog title="设置密码" :visible.sync="verificationShow" width="18%" :center="true" top="20vh" @close="close">
      <div class="ipts">
        <!-- <el-input v-model="phone" placeholder="手机号"></el-input>
        <div class="bindipt">
          <el-input v-model="code" placeholder="短信验证码"></el-input><span class="code" @click="clickGetCode">{{
            show ? "获取验证码" : count + "s"
          }}</span>
        </div> -->
        <el-input v-model="password" placeholder="密码" type="password"></el-input>
      </div>
      <span class="bindPhone">
        <el-button type="primary" @click="determinePhon">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  bindPhone,
  resetPassword,
} from "@/api/me.js"
import { getCode } from "@/api/signRegister.js"
import md5 from "js-md5"
import {mapState} from "vuex";
export default {
  components: {

  },
  data () {
    return {
      verificationShow: false,
      phone: '',
      password: '',
      code: '',
      show: true,
    }
  },
  computed: {
    ...mapState(['showBinding']),
  },
  watch: {
    "$store.state.showBinding" (a) {
      if (a) this.open()
    }
  },
  methods: {
    // 确定手机号修改
    determinePhon () {
      resetPassword({ old_password: '', new_password: this.password }).then(res => {
        this.$message.success("绑定成功")
        this.verificationShow=false
      })

      return
      if (!this.phone) {
        return this.$message({
          message: "手机号不能为空",
          type: "error",
          duration: 1800,
        })
      }
      if (this.phone.length != 11) {
        return this.$message({
          message: "手机号格式不正确",
          type: "error",
          duration: 1800,
        })
      }
      if (!this.code) {
        return this.$message({
          message: "验证码不能为空",
          type: "error",
          duration: 1800,
        })
      }
      if (!this.password) {
        return this.$message({
          message: "密码不能为空",
          type: "error",
          duration: 1800,
        })
      }
      bindPhone({ phone: this.phone, code: this.code })
        .then(({ data }) => {
          this.$message.success("修改成功")
          console.log(data, "手机号绑定成功")
        })

    },
    close(){
      this.verificationShow = false
      this.$store.state.showBinding = false
    },
    open () {
      this.$confirm("您还没有设置密码，需要设置吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.verificationShow = true
        })
        .catch(() => {
          this.close()
        })
    },
    // 点击获取验证码
    clickGetCode () {
      if (!this.show) {
        return
      }
      if (!this.phone) {
        return this.$message({
          message: "手机号不能为空",
          type: "error",
          duration: 1800,
        })
      }
      if (this.phone.length != 11) {
        return this.$message({
          message: "手机号格式不正确",
          type: "error",
          duration: 1800,
        })
      }
      let phone = this.phone - 0
      let time_stamp = new Date().getTime()
      let sign = md5(md5(phone + "bibao" + time_stamp))
      getCode({ phone: phone, time_stamp: time_stamp, sign: sign })
        .then((res) => {
          console.log(res, "验证码成功")
          this.$message.success("验证码发送成功")
          this.getCode()
        })
    },
  }
}
</script>

<style lang="less" scoped>
.bindipt {
  display: flex;
  margin: 10% 0;
  span {
    height: 40px;
    line-height: 40px;

    display: inline-block;
    white-space: nowrap;
    // padding: 0 5%;
    text-align: center;
    width: 45%;
    letter-spacing: 1px;
    background-color: #00957e;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
  }
}
.bindPhone {
  .el-button {
    margin-top: 20%;

    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    background: #00957e;
    font-weight: 600;
    color: #ffffff;
    border-color: #00957e;
  }
}
</style>
